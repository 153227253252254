import React, { useRef, useState } from 'react'
import Slick from 'react-slick'

import { classnames } from 'begonya/functions'

import Image from '@/components/base/image'
import Button from '@/components/base/button'
import Icon from '@/components/base/icon'

import { OurProductsProps } from './types'

const OurProducts: React.FunctionComponent<OurProductsProps> = ({
  data = [],
}) => {
  const slider = useRef<Slick>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    afterChange: (index: number) => setCurrentSlide(index),
  }

  const handlePrevClick = () => {
    if (slider.current) {
      slider.current.slickPrev()
    }
  }

  const handleNextClick = () => {
    if (slider.current) {
      slider.current.slickNext()
    }
  }

  const handleClick = (index: number) => {
    setCurrentSlide(index)
    if (slider.current) {
      slider.current.slickGoTo(index)
    }
  }

  const renderNavigation = () => {
    return (
      <div className="slick-navigation">
        <Button className="arrow-btn prev" onClick={handlePrevClick}>
          <Icon name="arrow-back" />
        </Button>
        <span className="number">{currentSlide + 1}</span> /
        <span className="number">{data.length}</span>
        <Button className="arrow-btn next" onClick={handleNextClick}>
          <Icon name="arrow-forward" />
        </Button>
      </div>
    )
  }

  const flexSlider = () => {
    return (
      <div className="flexbox-slider">
        {data.map((item, index) => {
          let zIndex

          if (currentSlide < data.length / 2) {
            zIndex = data.length - index
          } else {
            zIndex = index + 1
          }

          return (
            <div
              className={classnames(
                'flexbox-slide',
                currentSlide === index && 'flexbox-slide--active',
                currentSlide === index && 'active'
              )}
              style={{ zIndex }}
              key={index}
              onClick={() => handleClick(index)}
              onKeyDown={() => handleClick(index)}
              role="button"
              tabIndex={0}
            >
              <Image {...item.thumbnail} alt={item.title} />
              <div className="title title--left">{item.title}</div>
              <div className="title title--right">{item.title}</div>
              <div className="title title--bottom">{item.title}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="our-products">
      <Slick {...settings} ref={slider}>
        {data.map((item, index) => (
          <div key={index}>
            <Image
              className="background-image"
              {...item.image}
              alt={item.title}
            />
          </div>
        ))}
      </Slick>
      {renderNavigation()}
      {flexSlider()}
    </div>
  )
}

export default OurProducts
